.info-block {
	display: flex;
	align-items: center;
	overflow-x: hidden;
	width: 100%;

	&__container {
		display: flex;
		align-items: end;
		gap: 8px;
		align-self: stretch;
		width: 100%;
		padding-right: 16px;
	}

	&-item {
		position: relative;
		min-width: 210px;
		min-height: 132px;
		max-width: 100px;
		max-height: 100px;
		padding: 12px 0px 0px 12px;
		border-radius: 12px;
		display: flex;
		justify-content: start;
		align-items: start;
		padding-top: 25px;
		margin-left: 16px;

		&-text {
			color: #ffffff;
			font-family: 'Gooseberry';
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: -0.078px;
		}

		img {
			position: absolute;
			right: 0;
			bottom: 0;
			width: min-content;
			height: min-content;

		}
		&:nth-child(1){
			margin-left: 16px;
		}
        &:nth-child(1) img{
            width: 67px;
            height: 61px;
        }
        &:nth-child(2) img{
            width: 66px;
            height: 70px;
        }
        &:nth-child(3) img{
            width: 46px;
            height: 46px;
        }
        &:nth-child(4) img{
            width: 43px;
            height: 48px;
        }
        &:nth-child(5) img{
            width: 67px;
            height: 61px;
        }
	}
}
