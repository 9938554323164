@import '../../../styles/variables.scss';

.DAS-header {
	min-height: 50px;
	display: flex;
	align-content: space-between;
	z-index: 151;
	width: 100%;
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	align-items: center;
	padding-top: 19px;
	padding-left: 16px;
    padding-right: 16px;
	img {
		width: 45px;
		height: 23px;
	}
	&__payments_container{
		display: flex;
		flex-direction: column;
		h2{
			font-size: 24px;
			text-align: right;
		}
	}
	&__payments{
		display: flex;
		gap:10px;
		justify-content: flex-end;
	}
	&__container{
		display: flex;
		align-items: center;
		img {
			width: 45px;
			height: 45px;
			border-radius: 36px;
		}
		gap:24px;
	}
	&__payment{
		img {
			width: 16px;
			height: 16px;
		}
		gap:5px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		p{
			font-size: 16px;
			
		}
	}
	&_sticky {
		position: sticky;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		background-color: $color-primary;
		color: $color-secondary;
		z-index: 50;
		border-radius: 0 0 10px 10px;
	}
}
