.main-module {
	z-index: 100;
	&__block_1 {
		background-image: url("../../../images/block_1.png");
		height: 135px;
		width: 355px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-left: 15px;
		padding-top: 25px;
		gap: 5px;
		h2{
			font-size: 32px;
			line-height: 22px;
			
		}
		h3{
			font-size: 15px;
		}
	}
	&__block_2 {
		background-image: url("../../../images/block_2.png");
		height: 135px;
		width: 355px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-left: 15px;
		padding-top: 25px;
		gap: 5px;
		h2{
			font-size: 32px;
			line-height: 22px;
			
		}
		h3{
			font-size: 15px;
		}
	}
	&__block_3 {
		background-image: url("../../../images/block_3.png");
		height: 120px;
		width: 355px;
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-left: 15px;
		padding-top: 25px;
		gap: 5px;
		h2{
			font-size: 32px;
			line-height: 22px;
			
		}
		h3{
			font-size: 15px;
		}
	}
	&__block_game {
		background-image: url("../../../images/block_game.png");
		height: 152px;
		width: 100%;
		background-repeat: no-repeat;
		h2{
			color: #FFB800;
		}
	}
	&__block_game_1 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-left: 15px;
		padding-top: 25px;
		gap: 15px;
		h2{
			font-size: 64px;
			line-height: 22px;
			color: white;
			
		}
		h3{
			font-size: 15px;
		}
		height: 152px;
		width: 355px;
	}
	&__block_table {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		h2{
			color: #FFB800;
		}
		table{
			width: 100%;
			background-color: #262451;
			border-radius: 12px;
			height: auto;
			padding: 10px;
			border-spacing: 0 10px;
		}
		
		
	}
	&__block_table_td{
		margin-bottom: 10px;	
		background: linear-gradient(90deg, rgba(38, 36, 81, 0.00) 0%, rgba(86, 81, 183, 0.20) 50.67%, rgba(38, 36, 81, 0.00) 100%);
	}
	&__block_table_th{
		height: 20px;
		width: 100%;
		text-align: left;
		color:#605E9A;
	}
	&__block_table_td_img{
		display: flex;
		width: 80px;
		gap:10px;
		img{
			width: 36px;
			height: 36px;
			margin-bottom: 2px;
    		margin-top: 2px;
			border-radius: 8px;
		}
	}
	&__block_orange{
		color: #FFB800;
		// font-size: 11px;
		// font-style: normal;
		// font-weight: 500;
		// line-height: 11px; /* 100% */	
	}
	
	&__block_balance_container{
		display: flex;
		flex-direction: row;
		align-items: center;
		h1{
			color: #1CFFA0;
			font-size: 48px;
			font-weight: 600;
			line-height: 48px;
		}
		p{
			font-size: 48px;
			font-weight: 600;
			line-height: 48px;
		}
		img{
			height: 24px;
		}
	}
	&__block_footer{
		background-color: #262451;
		height: 58px;
		width: 100%;
		margin-top: 20px;
		border-radius: 29px;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 79px;
		padding-right: 79px;
	}
	// &__block_table_td{
	// 	height: 40px;
	// }
	&__block_footer_container{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		width: 100%;
		z-index: 100;
		animation: blink 2s;
	}

	&-bottom {
		position: relative;
		bottom: 15px;
		animation: blink 2s;
	}

	&__shake {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		z-index: 100;
		margin-top: 115px;
		margin-bottom: 145px;
		animation: blink 2s;

		&-title {
			color: #fff;
			font-family: 'Gooseberry';
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 130%;
			letter-spacing: -0.442px;
			text-transform: uppercase;
			animation: blink 2s;
		}

		&-subtitle {
			color: #fff;
			font-family: 'Gooseberry';
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: -0.442px;
			text-transform: uppercase;
			animation: blink 2s;
		}
	}

	&__payment{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
	}
}
