.truncate-middle {
    display: inline-block;
    max-width: 200px; /* Ширина контейнера */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.truncate-middle:before {
content: attr(data-start);
display: inline;
}

.truncate-middle:after {
content: attr(data-end);
display: inline;
}
.block_balance{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 25%;
	
}
.block_balance h2{
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}
.block_balance_container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.block_balance_container h1{
    color: #1CFFA0;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    margin-right: 10px;
}
.block_balance_container p{
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
}
.block_balance_container img{
    height: 24px;
    margin-left: 10px;
}

.block_toncoin{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    background: #262451;
    padding: 12px;
    justify-content: space-between;
}

.block_toncoin_image{
    display: flex;
}
.block_toncoin_left{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;
}
.block_toncoin_left p{
    color: #457BA6;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.23px;
}
.block_toncoin_text{
    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 91.667% */
    letter-spacing: -0.4px;
}

.block_balance_history{
    width: 100%;
    border-radius: 12px;
    background: #262451;
    padding: 16px;
}
.block_balance_history h2{
    color: #FFB800;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px; /* 100% */
}

.block_balance_history_block{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
}

.block_balance_history_wrapper{
    gap: 16px;
    display: flex;
    flex-direction: row;
}

.block_balance_history_item{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    justify-content: space-between;
}
.block_balance_history_block h2{
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}
.block_balance_history_block p{
    color: #457BA6;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}
.block_balance_history_text{
    gap: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.block_balance_history_text h2{
    color: #1CFFA0;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
}

.block_balance_history_text p{
    color: #1CFFA0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
}

.block_payment_header{
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    margin-top: 59px;
    justify-content: flex-start;
    align-items: center;
}
.block_payment_header img{
    width: 40px;
    height: 40px;
}
.block_payment_header h2{
    color: #FFF;

    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 100% */
}
.block_payment_sum{
    display: flex;
    width: 100%;
}
.block_payment_sum h2{
    color: #1CFFA0;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.4px;
}
.block_payment_status{
    width: 100%;
}
.block_payment_status h3{
    color: #457BA6;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */
}
.block_payment_block_status{
    border-radius: 16px;
    background: #262451;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
}

.block_payment_block_status h3{
    color: #457BA6;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
}

.block_payment_block_status h4{
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px; /* 100% */
}
.block_payment_wrapp{
    width: 100%;
    margin-bottom: 12px;
}

.block_profile_info h2{
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 100% */
}
.block_profile_info h3{
    color: #457BA6;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */

}
.block_profile{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}
.block_profile_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.block_profile_image{
    width: 96px;
    height: 96px;
}
.text_white h2{
    color: white;
}
.text_white p{
    color: #457BA6;
}
.block_profile_image img{
    width: 96px;
    height: 96px;
    border-radius: 180px;
}