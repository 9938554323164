@import "../../styles/variables.scss";

.circle-preloader{
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		width: 157px;
	}
	&__container{
		width: 167px;
    	font-size: 26px;
	}
	&__circle {
		display: inline-block;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@keyframes spinner{
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}