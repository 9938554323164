html,
body {
  padding: 0;
  margin: 0;
  background: #072333;
  font-family: din-2014,sans-serif;
  font-weight: 800;
  font-style: normal;
}

* {
  box-sizing: border-box;
}




.fullSlot {
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: calc(100% - 60px);
  left: 0;
  right: 0;
  margin: auto;
  align-items: center;
}

.casinoName {
  font-family: fino-sans,sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.781);
  width: 100%;
  padding: 10px 0 0 0;
}

.price {
  font-weight: 400;
  font-style: normal;
  color: #0098EA;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.priceInd {
  margin: 0;
  animation: premio .5s forwards linear;
}

.renewButton {
  margin: 20px 0 0 0;
  padding: 20px;
  line-height: 0;
}

@keyframes premio {
  0% {transform: translateY(100px);}
  80% {transform: translateY(-5px);}
  100% {transform: translateY(0);}
}

.slot {
  display: flex;
  flex-direction: row;
  width: 110%;
  height: 400px;
  overflow: hidden;
  background-image: url("../../../images/background-slot.png");
}

.slotFoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.slotTab{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
}
.slotVal{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.slotValS{
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #0098EA;
    justify-content: space-between;
}
.slotValS p{
    color:#0098EA;
}
.slotTab h3{
    font-size: 24px;
    color:#457BA6;
}
.slotInputSum{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.slotSum{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color:#1CFF9F;
    margin-bottom: 5px;
}
.slotSum p{
    color: #1CFF9F;
    font-size: 18px;
}
.slotValCont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap:4px;
    margin-top: 10px;
}
.slotValContButton{
text-align: center;
font-size: 20px;
width: 110px;
height: 32px;
margin-bottom: 10px;
background-color: #2C2D3A;
border-radius: 8px;
border: unset;
color: white;
font-family: 'Gooseberry';
}

.betInput {
  font-weight: 400;
  font-style: normal;
  text-align: left;
  background-size: 20px;
  border: none;
  border-radius: 2px;
  font-size: 1.2em;
  margin: 20px;
  height: 40px;
  width: 100%;
  margin: 0 5px 0 0;
  flex: 1 1 auto;
  text-align: left;
  padding-left: 15px;
  border-radius: 12px;
  background-color: unset;
  color:white;
  border:1px solid #3B3C4E;
  font-family: 'Gooseberry';
}

.spinButton {
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  font-family: hydrophilia-iced,sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 1.2em;
  margin: 0 0 0 5px;
  flex: 1 1 auto;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 2px;
  background-image: url("../../../images/bacground-button.png");
  color:#1A183D;
  border-radius: 12px;
}

.slotTab h3.active{
    color: white;
}

.buyMoreButton {
  text-align: center;
  font-size: 20px;
  width: 89px;
  height: 32px;
  margin-bottom: 10px;
  background-color: #2C2D3A;
  border-radius: 8px;
  border: unset;
  color: white;
  font-family: 'Gooseberry';
}
.slotButtonCont{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4px;
    margin-top: 4px;
}
.row {
  flex: 1 0 auto;
  margin: 0 5px 0 5px;
  overflow: hidden;
}

.ringMoving {
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 4em;
  animation: goaround 0.2s linear infinite;
}

.ringEnd {
  height: 125px;
  line-height: 55px;
  text-align: center;
  font-size: 4em;
  margin-bottom: 10px;
}

@keyframes goaround {
0% {transform: translateY(0);}
100% {transform: translateY(-1000px);}
}

@keyframes stop {
  0% {transform: translateY(0)}
  50% {transform: translateY(-90px)}
  75% {transform: translateY(-80px)}
  100% {transform: translateY(-85px)}
  }

.test{
  background-color: #FAB10E;
  color:#1A183D;
}

.textColor{
  color: white;
}